import {useLoaderData} from '@remix-run/react';
import {LoaderArgs} from '@shopify/remix-oxygen';
import {AdvertisingPage} from '~/features/advertising';

export {meta} from '~/utils/meta';

export async function loader({context, params}: LoaderArgs) {
  const data = await context.getAdvertisingPageService.get(
    params.handle as string
  );

  if (!data) {
    throw new Response('Not found', {status: 404});
  }

  return data;
}

export default function Advertising() {
  const data = useLoaderData<typeof loader>();

  return <AdvertisingPage data={data} />;
}
