import {useTranslation} from 'react-i18next';
import {Center} from '~/components/Center';
import {Hero} from '~/components/Hero';
import {HorizontalDivider} from '~/components/HorizontalDivider';
import {ProjectSummaryCard} from '~/features/projects';
import {MediaShowcase} from '~/features/shared';
import {Clients} from '~/pages/HomePage/Clients';
import {AdvertisingPageType} from '../../services/GetAdvertisingPageService';
import {SectionQuote} from './SectionQuote';

interface AdvertisingPageProps {
  data: AdvertisingPageType;
}

export function AdvertisingPage({data}: AdvertisingPageProps) {
  const {t} = useTranslation();

  const page = data.page;
  const basePage = data.basePage;

  const projects = page.proyectos;

  return (
    <>
      <Hero
        isHtmlTitle
        title={page.tituloPrincipal}
        video={page.videoPrincipal}
      />

      <Center as="section" className="pt-20 w-full">
        <div className="px-5 md:px-10 lg:px-30">
          <h1
            className="mb-16 text-4xl text-center uppercase sm:text-5xl text-blue-dark"
            dangerouslySetInnerHTML={{
              __html: page.tituloPagina,
            }}
          />
          <div
            className="mb-10 font-medium text-gray-600 rich-text"
            dangerouslySetInnerHTML={{
              __html: basePage.body,
            }}
          />

          <MediaShowcase
            height={400}
            key={basePage.handle + '-desktop'}
            media={page.media}
          />
        </div>
      </Center>

      <Center className="px-5 w-full md:px-10 lg:px-30">
        <HorizontalDivider className="mt-20 mb-0" />
      </Center>

      <Clients clientes={page.clientes} />

      {projects.length ? (
        <section className="pt-20 pb-32 bg-black">
          <Center className="px-5 md:px-10 lg:px-30">
            <div>
              <h1 className="mb-10 text-3xl font-bold text-white uppercase sm:mb-16 sm:text-4xl">
                {t('pages:template:nuestros-proyectos')}
              </h1>
            </div>

            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              {projects.map((p) => {
                return <ProjectSummaryCard project={p} key={p.handle} />;
              })}
            </div>
          </Center>
        </section>
      ) : null}

      <SectionQuote image={basePage.cotizarImage} />
    </>
  );
}
